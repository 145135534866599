import { render, staticRenderFns } from "./SidebarFilter.vue?vue&type=template&id=482ce912&scoped=true"
import script from "./SidebarFilter.vue?vue&type=script&lang=js"
export * from "./SidebarFilter.vue?vue&type=script&lang=js"
import style0 from "./SidebarFilter.vue?vue&type=style&index=0&id=482ce912&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "482ce912",
  null
  
)

export default component.exports